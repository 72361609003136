import { mustBeEthereumAddress } from '@/helpers/abi/utils';
export var isAddress = function (type) {
    return type.indexOf('address') === 0;
};
export var isBoolean = function (type) { return type.indexOf('bool') === 0; };
export var isString = function (type) { return type.indexOf('string') === 0; };
export var isUint = function (type) { return type.indexOf('uint') === 0; };
export var isInt = function (type) { return type.indexOf('int') === 0; };
export var isByte = function (type) { return type.indexOf('byte') === 0; };
export var isArrayParameter = function (parameter) {
    return /(\[\d*])+$/.test(parameter);
};
export var isStringArray = function (text) {
    try {
        var values = JSON.parse(text);
        return Array.isArray(values);
    }
    catch (e) {
        return false;
    }
};
export var isParameterValue = function (type, value) {
    if (type === 'address') {
        return mustBeEthereumAddress(value);
    }
    else if (isArrayParameter(type)) {
        return isStringArray(value);
    }
    return !!value;
};
